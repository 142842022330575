@import '../../../css/colors.scss';

#dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 1rem;
  grid-auto-flow: dense;

  .box {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background: white;
    padding: 1rem 2rem;
    border-radius: 3px;
    box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.19), 0 2px 2px rgba(0, 0, 0, 0.23);
    min-height: 110px;

    .number {
      font-size: x-large;
    }

    .number-card-title {
      position: absolute;
      top: 15px;
      left: 20px;
      margin-bottom: 10px;
      color: $bdu-primary;
    }
  }
}
