nav {
  .dropdown-menu {
    .nav-link {
      white-space: nowrap;
    }
  }
  .align-collapse-right {
    .dropdown-menu {
      right: 0;
      left: unset;
    }
  }
}
