@import '../../../css/breakpoints.scss';

#registration {
  thead {
    display: none;
  }
  i {
    font-size: 2rem;
  }
}
